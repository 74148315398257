import HelloWorld from './HelloWorld.vue'
import Page from './page/Page.vue'
import CustomCard from './Card.vue'
import BreadCrumbItem from './BreadCrumbItem.vue'
import Tab from './tab/Tab.vue'
import TabSelector from './tab/TabSelector.vue'
import TabTitle from './tab/TabTitle.vue'
import TableSort from './table/TableSort.vue'
import RadioBtn from './RadioBtn.vue'
import CustomModal from './CustomModal.vue'
import LoadingSpinner from './LoadingSpinner.vue'
import PerfilInfo from './PerfilInfo.vue'
import CustomAccordion from './CustomAccordion.vue'
import CustomBadge from './CustomBadge.vue'
import CustomInput from './formInputs/CustomInput.vue'
import DatePickerInput from './formInputs/DatePickerInput.vue'
import MoneteryInput from './formInputs/MoneteryInput.vue'
import MultiselectInput from './formInputs/MultiselectInput.vue'
import NumberInput from './formInputs/NumberInput.vue'
import PercentageInput from './formInputs/PercentageInput.vue'
import RadioBtnInput from './formInputs/RadioBtnInput.vue'
import TextInput from './formInputs/TextInput.vue'
import TextareaInput from './formInputs/TextareaInput.vue'
import ZipInput from './formInputs/ZipInput.vue'
import PhoneNumberInput from './formInputs/PhoneNumberInput.vue'
import HideTextInput from './formInputs/HideTextInput.vue'
import EyeIcon from './EyeIcon.vue'
import CloseEyeIcon from './CloseEyeIcon.vue'
import DateTimePickerInput from './formInputs/DateTimePickerInput.vue'
import TooltipLib from './tooltip.vue'
import InformationIcon from './informationIcon.vue'
import TableSortPagination from './table/TableSortPagination.vue'

export default { 
    HelloWorld,
    Page,
    CustomCard,
    BreadCrumbItem,
    Tab,
    TabSelector,
    TabTitle,
    TableSort,
    RadioBtn,
    CustomModal,
    LoadingSpinner,
    PerfilInfo,
    CustomAccordion,
    CustomBadge,
    DatePickerInput,
    CustomInput,
    MoneteryInput,
    MultiselectInput,
    NumberInput,
    PercentageInput,
    RadioBtnInput,
    TextInput,
    TextareaInput,
    ZipInput,
    PhoneNumberInput,
    EyeIcon,
    CloseEyeIcon,
    HideTextInput,
    DateTimePickerInput,
    TooltipLib,
    InformationIcon,
    TableSortPagination
 }