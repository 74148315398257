<template>
  <input
    @paste.prevent
    :class="{ invalid: showErrorMsg }"
    type="text"
    class="form-control"
    :placeholder="localPlaceholder"
    v-model="localModelValue"
    :disabled="disabled"
    @keypress="mask"
    :maxlength="maxlength"
  />
</template>

<script>
export default {
  props: {
    placeholder: String,
    showErrorMsg: {
      Boolean,
      default: false,
    },
    modelValue: String,
    disabled: {
      Boolean,
      default: false,
    },
    maxlength: {
      Number,
      default: 20,
    },
    maxval: {
      Number,
      default: null,
    },
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
    mask: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      var char = String.fromCharCode(charCode);
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 44 &&
        charCode != 43
      ) {
        evt.preventDefault();
      } else {
        if (
          this.maxval != null &&
          parseInt(this.localModelValue + char) > this.maxval
        ) {
          evt.preventDefault();
        }
        return true;
      }
    },
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
    localPlaceholder() {
      return this.placeholder ?? this.selectedLang.add;
    },
    localErrorMsg() {
      return this.placeholder ?? this.selectedLang.invalidValue;
    },
  },
};
</script>
