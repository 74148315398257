<template>
  <div class="page-header">
    <span class="title">
      {{ siteHeader }}
    </span>

    <div class="ml-auto"><slot name="perfilContent"></slot></div>
  </div>
</template>

<script>
export default {
  name: "PHeader",
  components: {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    siteHeader() {
      if (this.selectedLang == null) {
        return "Plataforma Eletrónica da Entidade para a Transparência";
      }

      return this.selectedLang.siteTitle;
    },
  },
  mounted() {},
  created() {},
};
</script>
