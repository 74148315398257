<template>
  <li
    class="customBreadcrumb-item"
    :class="{ 'customBreadcrumb-item-href': showArrowAfter }"
  >
    <template v-if="backFunction != null">
      <a href="" @click.prevent="backFunction">
        {{ this.title }}
      </a>
    </template>
    <template v-else>
      <router-link v-if="toName != null" :to="{ name: toName }">{{
        this.title
      }}</router-link>
      <router-link v-if="hrefIsNotEmpty" :to="href">{{
        this.title
      }}</router-link>
      <a v-else>{{ this.title }}</a>
    </template>
  </li>
</template>
<script>
export default {
  name: "BreadCrumbItem",
  props: {
    toName: String,
    title: String,
    href: String,
    backFunction: Function,
  },
  computed: {
    showArrowAfter() {
      if (
        this.backFunction != null ||
        this.toName != null ||
        this.hrefIsNotEmpty
      ) {
        return true;
      }

      return false;
    },
    hrefIsNotEmpty() {
      return this.href != null && this.href != "";
    },
  },
};
</script>
