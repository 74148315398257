<template>
  <div class="table-responsive">
    <table class="table table-bordered">
      <TableSortHeader
        :headers="tableModel.headers"
        :loading="loading"
        :checkBox="checkBox"
        :sortFunc="sortFunc"
        :actions="actions"
        :sortHeader="sortHeader"
        :defaultSort="defaultSort"
      />
      <TableSortBody
        :loading="loading"
        :checkBox="checkBox"
        :totalItems="totalItems"
        :showEmptyrow="showEmptyrow"
      >
        <slot></slot>
      </TableSortBody>
    </table>

    <TableSortPagination
      :searchFunc="searchFunc"
      :total="tableModel.total"
      :pages="tableModel.pages"
      :showTotal="showTotal"
      :showPagination="showPagination"
    />
  </div>
</template>

<script>
import TableSortHeader from "./TableSortHeader.vue";
import TableSortBody from "./TableSortBody.vue";
import TableSortPagination from "./TableSortPagination.vue";

export default {
  components: { TableSortHeader, TableSortBody, TableSortPagination },
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: true,
    },
    showPagination: {
      type: Boolean,
      required: false,
      default: true,
    },
    showTotal: {
      type: Boolean,
      required: false,
      default: true,
    },
    sortHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
    tableModel: {
      type: Object,
      required: true,
      default() {
        return {
          headers: ["header1", "header2"],
        };
      },
    },
    checkBox: {
      type: Boolean,
      required: false,
    },
    actions: {
      type: Boolean,
      required: false,
    },
    sortFunc: {
      type: Function,
      required: false,
    },
    searchFunc: {
      type: Function,
      required: false,
    },
    defaultSort: {
      type: String,
      required: true,
    },
    // value: {
    //   type: Object,
    //   required: true,
    // },

    showEmptyrow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    // model: {
    //   get() {
    //     return this.value;
    //   },
    //   set(value) {
    //     this.$emit("input", value);
    //   },
    // },
    totalItems() {
      return this.tableModel.total;
    },
  },
  mounted() {},
};
</script>
