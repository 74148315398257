<template>
  <div>
    <label
      v-if="showLabel"
      :class="{
        required: required,
        invalid: showErrorMsg,
      }"
      class="form-label"
      >{{ label }}

      <InformationIcon
        style="width: 16px; margin-left: 5px"
        v-if="tooltipInfo != null"
        v-tooltip="tooltipInfo"
      />
    </label>

    <DatePickerInput
      @paste.prevent
      v-if="isDatePickerInput"
      v-model="localModelValue"
      :placeholder="placeholder"
      :showErrorMsg="showErrorMsg"
      :disabled="disabled"
      :maxDate="maxDate"
      :timePicker="timePicker"
      :enableTimePicker="enableTimePicker"
      :showNowButton="showNowButton"
      :autoApply="autoApply"
      :textInput="textInput"
      :minDate="minDate"
      @update="update"
    />

    <DateTimePickerInput
      @paste.prevent
      v-if="isDateTimePickerInput"
      v-model="localModelValue"
      :placeholder="placeholder"
      :showErrorMsg="showErrorMsg"
      :disabled="disabled"
      :maxDate="maxDate"
      :minDate="minDate"
      :timePicker="timePicker"
      :enableTimePicker="enableTimePicker"
      :showNowButton="showNowButton"
      :autoApply="autoApply"
      :textInput="textInput"
      @update="update"
    />

    <MoneteryInput
      @paste.prevent
      v-if="isMoneteryInput"
      v-model="localModelValue"
      :placeholder="placeholder"
      :showErrorMsg="showErrorMsg"
      :disabled="disabled"
      :maxlength="maxlength"
    />

    <MultiselectInput
      @paste.prevent
      v-if="isMultiselectInput"
      v-model="localModelValue"
      :placeholder="placeholder"
      :showErrorMsg="showErrorMsg"
      :disabled="disabled"
      :maxlength="maxlength"
      :options="options"
      :searchable="searchable"
      :mode="mode"
      :closeOnSelect="closeOnSelect"
      @clear="clear"
      @select="select"
      :loading="loading"
      :groups="groups"
      :showOptions="showOptions"
      @search-change="searchChange"
      :createOption="createOption"
      :handleTagCreate="handleTagCreate"
      :showOtherOption="showOtherOption"
      :otherOptionValue="otherOptionValue"
    />
    <RadioBtnInput
      @paste.prevent
      v-if="isRadioBtnInput"
      v-model="localModelValue"
      :placeholder="placeholder"
      :showErrorMsg="showErrorMsg"
      :disabled="disabled"
      :maxlength="maxlength"
      :options="options"
      :searchable="searchable"
      :mode="mode"
      @clear="clear"
      @select="select"
      :parseIntProp="parseIntProp"
      :groupName="groupName"
    />

    <NumberInput
      @paste.prevent
      v-if="isNumberInput"
      v-model="localModelValue"
      :placeholder="placeholder"
      :showErrorMsg="showErrorMsg"
      :disabled="disabled"
      :maxlength="maxlength"
      :maxval="maxval"
    />

    <PhoneNumberInput
      @paste.prevent
      v-if="isPhoneNumberInput"
      v-model="localModelValue"
      :placeholder="placeholder"
      :showErrorMsg="showErrorMsg"
      :disabled="disabled"
      :maxlength="maxlength"
      :maxval="maxval"
    />

    <TextInput
      v-if="isTextInput"
      v-model="localModelValue"
      :placeholder="placeholder"
      :showErrorMsg="showErrorMsg"
      :disabled="disabled"
      :maxlength="maxlength"
      :showTooltip="showTooltip"
    />
    <HideTextInput
      v-if="isHideTextInput"
      v-model="localModelValue"
      :placeholder="placeholder"
      :showErrorMsg="showErrorMsg"
      :disabled="disabled"
      :maxlength="maxlength"
    />

    <TextareaInput
      v-if="isTextareaInput"
      v-model="localModelValue"
      :placeholder="placeholder"
      :showErrorMsg="showErrorMsg"
      :disabled="disabled"
      :maxlength="maxlength"
      :rows="rows"
      :showTooltip="showTooltip"
    />

    <ZipInput
      @paste.prevent
      v-if="isZipInput"
      v-model="localModelValue"
      :placeholder="placeholder"
      :showErrorMsg="showErrorMsg"
      :disabled="disabled"
      :maxlength="maxlength"
    />
    <FileInput
      @paste.prevent
      v-if="isFileInput"
      v-model="localModelValue"
      :placeholder="placeholder"
      :showErrorMsg="showErrorMsg"
      :disabled="disabled"
      :dashed="dashed"
      :accept="localAccept"
    />
    <PercentageInput
      @paste.prevent
      v-if="isPercentageInput"
      v-model="localModelValue"
      :placeholder="placeholder"
      :showErrorMsg="showErrorMsg"
      :disabled="disabled"
      :maxlength="maxlength"
    />

    <InputInvalidMsg :show="showErrorMsg" :msg="localErrorMsg" />
  </div>
</template>

<script>
export default {
  component: {},
  props: {
    maxDate: [Date, String],
    minDate: [Date, String],
    type: { String, default: "isTextInput" },
    label: String,
    placeholder: String,
    errorMsg: String,
    showErrorMsg: {
      Boolean,
      default: false,
    },
    modelValue: [String, Date, Number, File, Array, Object, Boolean],
    disabled: {
      Boolean,
      default: false,
    },
    closeOnSelect: {
      Boolean,
      default: true,
    },
    required: {
      Boolean,
      default: false,
    },
    dashed: {
      Boolean,
      default: false,
    },
    maxlength: {
      Number,
      default: 20,
    },
    options: {
      Array,
      default: [],
    },
    searchable: {
      Boolean,
      default: true,
    },
    mode: {
      String,
      default: "single", //multiple
    },
    maxval: {
      Number,
      default: null,
    },
    loading: {
      Boolean,
      default: false,
    },
    groups: {
      Boolean,
      default: false,
    },
    showOptions: {
      Boolean,
      default: true,
    },
    parseIntProp: {
      Boolean,
      default: true,
    },
    timePicker: {
      Boolean,
      default: false,
    },
    enableTimePicker: {
      Boolean,
      default: false,
    },
    showNowButton: {
      Boolean,
      default: true,
    },
    autoApply: {
      Boolean,
      default: true,
    },
    groupName: {
      String,
      default: "Group",
    },
    textInput: {
      Boolean,
      default: true,
    },
    rows: {
      Number,
      default: 1,
    },
    createOption: {
      Boolean,
      default: false,
    },
    handleTagCreate: {
      Function,
      default: null,
    },
    showOtherOption: {
      Boolean,
      default: false,
    },
    showTooltip: {
      Boolean,
      default: false,
    },
    otherOptionValue: {
      Object,
      default: {
        value: -1,
        label: "Outro",
      },
    },
    tooltipInfo: {
      String,
      default: null,
    },
    tooltipInfoSize: {
      String,
      default: "medium",
    },
    accept: {
      type: [String, Array],
      default() {
        return ["xls", "xlsx", "doc", "docx", "pdf"];
      },
    },
  },
  methods: {
    localHandleTagCreate(newTag) {
      const tag = {
        label: newTag.label,
        value: this.options.length,
      };
      this.localOptions.push(tag);
      this.localModelValue = tag.value;

      return tag;
    },
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
    clear() {
      this.$emit("clear");
    },
    select(event) {
      this.$emit("select", event);
    },
    update(event) {
      this.$emit("update", event);
    },
    searchChange(event) {
      this.$emit("search-change", event);
    },
    isNullOrEmpty(value) {
      if (value === null || value === undefined) return true;

      if (value === "" || value.toString().trim() === "") {
        return true;
      }

      return false;
    },
  },
  computed: {
    localAccept() {
      let result = this.accept.map((x) => this.selectedLang.mimes[x]);
      return result;
    },
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
    localErrorMsg() {
      return this.errorMsg ?? this.selectedLang.requiredField;
    },
    haveTooltipInfo() {
      return this.tooltipInfo != null;
    },

    isDatePickerInput() {
      return this.type == "DatePicker";
    },

    isDateTimePickerInput() {
      return this.type == "DateTimePicker";
    },

    isMoneteryInput() {
      return this.type == "Monetery";
    },

    isMultiselectInput() {
      return this.type == "Multiselect";
    },

    isNumberInput() {
      return this.type == "Number";
    },

    isPhoneNumberInput() {
      return this.type == "PhoneNumber";
    },

    isTextInput() {
      return this.type == "Text";
    },

    isHideTextInput() {
      return this.type == "HideText";
    },

    isFileInput() {
      return this.type == "File";
    },

    isPercentageInput() {
      return this.type == "Percentage";
    },
    isRadioBtnInput() {
      return this.type == "RadioBtn";
    },
    isZipInput() {
      return this.type == "Zip";
    },
    isTextareaInput() {
      return this.type == "Textarea";
    },
    showLabel() {
      return !this.isNullOrEmpty(this.label);
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
