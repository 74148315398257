<template>
  <div class="">
    <div class="page">
      <div class="wrapper">
        <PageHeader v-if="showPageHeader">
          <template #perfilContent>
            <slot name="perfilContent"></slot>
          </template>
        </PageHeader>
        <template v-else>
          <slot name="customPageHeader"></slot>
        </template>
        <div class="page-content">
          <PageBackGround />
          <PageToolbar v-if="showPageHeader" :title="title">
            <template #breadcrumb>
              <slot name="breadcrumb"></slot>
            </template>
            <template #actions>
              <slot name="actions"></slot>
            </template>
          </PageToolbar>
          <PageContent>
            <slot name="content"></slot>
          </PageContent>
        </div>
        <PageFooter class="mt-5">
          <slot name="footer"></slot>
        </PageFooter>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/page/PageHeader.vue";
import PageFooter from "@/components/page/PageFooter.vue";
import PageContent from "@/components/page/PageContent.vue";
import PageToolbar from "@/components/page/PageToolbar.vue";
import PageBackGround from "@/components/page/PageBackGround.vue";

export default {
  name: "MainPage",
  components: {
    PageHeader,
    PageFooter,
    PageContent,
    PageToolbar,
    PageBackGround,
  },
  props: {
    title: String,
    showPageHeader: {
      Boolean,
      default: true,
    },
  },
  data: () => {
    return {};
  },
};
</script>
