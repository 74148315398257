<template>
  <DatePicker
    :class="{ invalid: showErrorMsg }"
    v-model="localModelValue"
    :showNowButton="showNowButton"
    :textInput="textInput"
    :autoApply="autoApply"
    :enableTimePicker="enableTimePicker"
    locale="PT-PT"
    :nowButtonLabel="selectedLang.datePickerNowButtonLabel"
    :placeholder="localPlaceholder"
    monthNameFormat="long"
    :format="format"
    :disabled="disabled"
    :textInputOptions="textInputOptions"
    :maxDate="maxDate"
    :minDate="minDate"
    :timePicker="timePicker"
    :cancel-text="cancelLabel"
    :select-text="selectLabel"
    :clearable="!enableTimePicker"
    @update:model-value="update"
    @closed="update"
  >
    <template #now-button="{ selectCurrentDate }">
      <span
        @click="selectCurrentDate()"
        class="btnAlike datePickerNowButtonLabel mb-2"
      >
        {{ selectedLang.datePickerNowButtonLabel }}
      </span>
    </template>

    <template #arrow-left>
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.5296 10.4551C21.6245 10.55 21.6719 10.6591 21.6719 10.7824C21.6719 10.9057 21.6245 11.0148 21.5296 11.1097L15.9373 16.702L21.5296 22.2944C21.6245 22.3893 21.6719 22.4984 21.6719 22.6217C21.6719 22.745 21.6245 22.8541 21.5296 22.949L20.8182 23.6605C20.7233 23.7553 20.6142 23.8028 20.4909 23.8028C20.3675 23.8028 20.2584 23.7553 20.1636 23.6605L13.5324 17.0293C13.4376 16.9345 13.3901 16.8254 13.3901 16.702C13.3901 16.5787 13.4376 16.4696 13.5324 16.3748L20.1636 9.74362C20.2584 9.64875 20.3675 9.60132 20.4909 9.60132C20.6142 9.60132 20.7233 9.64875 20.8182 9.74362L21.5296 10.4551Z"
          fill="#6A7178"
        />
      </svg>
    </template>
  </DatePicker>
</template>

<script>
import moment from "moment";
export default {
  props: {
    modelValue: [Date, Object, String],
    maxDate: Date,
    minDate: Date,
    placeholder: String,
    showErrorMsg: {
      Boolean,
      default: false,
    },
    disabled: {
      Boolean,
      default: false,
    },
    timePicker: {
      Boolean,
      default: false,
    },
    enableTimePicker: {
      Boolean,
      default: false,
    },
    showNowButton: {
      Boolean,
      default: true,
    },
    autoApply: {
      Boolean,
      default: true,
    },
    textInput: {
      Boolean,
      default: true,
    },
  },
  data() {
    return {
      textInputOptions: {
        format: "dd-MM-yyyy",
      },
    };
  },
  methods: {
    update(event) {
      this.$emit("update", event);
    },
    format(value) {
      let result = "";
      if (this.enableTimePicker) {
        result = this.timeToString(value);
      } else if (value != null) {
        var date = moment(String(value));
        result = date.format("DD-MM-YYYY");
      }
      return result;
    },
    timeToString(value) {
      let hoursAux = 0;
      let minutesAux = 0;
      if (value == null) {
        return "";
      }
      if (value.getHours) {
        hoursAux = value.getHours();
      } else {
        hoursAux = value.hours;
      }
      if (value.getMinutes) {
        minutesAux = value.getMinutes();
      } else {
        minutesAux = value.minutes;
      }

      let hours = hoursAux > 9 ? `${hoursAux}` : `0${hoursAux}`;
      let minutes = minutesAux > 9 ? `${minutesAux}` : `0${minutesAux}`;

      let result = `${hours}:${minutes}`;

      // console.log("timeToString(value) -> value:", value);
      // console.log("timeToString(value) -> result:", result);
      return result;
    },
    stringToTime(value) {
      let result = {};
      let timeValues = value.split(":");

      result = {
        hours: parseInt(timeValues[0]),
        minutes: parseInt(timeValues[1]),
        seconds: 0,
      };

      // console.log("stringToTime(value) -> value:", value);
      // console.log("stringToTime(value) -> result:", result);

      return result;
    },
  },
  computed: {
    localModelValue: {
      get() {
        let result = this.modelValue;
        if (this.enableTimePicker) {
          result = this.stringToTime(result);
        }
        return result;
      },
      set(newValue) {
        let result = newValue;
        if (this.enableTimePicker) {
          result = this.timeToString(newValue);
        }

        // console.log("!!!!!!!!!update:modelValue:");
        // console.log("newValue: ", newValue);
        // console.log("result: ", result);
        this.$emit("update:modelValue", result);
      },
    },
    localPlaceholder() {
      return this.placeholder ?? this.selectedLang.datePickerPlaceholder;
    },
    selectLabel() {
      return this.selectedLang.select ?? "Selecionar";
    },
    cancelLabel() {
      return this.selectedLang.cancel ?? "Cancelar";
    },
  },
};
</script>
