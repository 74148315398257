<template>
  <div class="tab-content-item" :class="{ hide: !this.isActive }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TabContent",
  props: {
    tabName: String,
    group: String,
    selected: { default: false },
  },
  data() {
    return {
      isActive: this.selected,
      name: this.tabName,
    };
  },
  mounted() {
    this.emitter.on("select-tab", (emitterObj) => {
      this.selectTab(emitterObj.tabName, emitterObj.group);
    });
  },
  computed: {
    getIsActive() {
      return this.isActive;
    },
  },
  methods: {
    selectTab(tabName, group) {
      if (group == this.group) {
        this.isActive = tabName == this.name;
      }
    },
  },
};
</script>
