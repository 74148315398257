<template>
    <div class="tooltip-container">
      <slot />
      <div class="tooltip" :class="tooltipDivClass">
        <span :class="tooltipSpanClass">{{ text }}</span>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "toolTip",
  
    data() {
      return {
        tooltipSpanClass: "",
        tooltipDivClass: "",
      };
    },
    mounted(){
      this.tooltipDivClass = this.whiteBorder ? this.size + " whiteBorderDiv" : this.size;
      this.tooltipSpanClass = this.whiteBorder ? this.sideArrow + " whiteBorder" : this.sideArrow;
  
    },
    props: {
      text: {
        type: String,
  
        default: function() {
          return "";
        },
        required: true,
      },
      whiteBorder: {
        type: Boolean,
        default: function() {
          return false;
        },
      },
      size: String,
      sideArrow: {
        type: String,
  
        default: function() {
          return "bottomArrow";
        },
      },
    },
  };
  </script>
  <style scoped>
 .tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-container:hover .tooltip {
  display: block;
  transition: opacity 1s;
}

.tooltip-container:disabled .tooltip {
  display: none;
  opacity: 0.5;
}

.tooltip {
  display: none;
  width: 120px;
  background-color: #102E40;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
  opacity: 1;
  border: 1px solid white;
}

.tooltip.small {
  text-align-last: center;
}

.tooltip.medium {
  width: 240px;
  margin-left: -120px;
  text-align-last: center;
}

.tooltip.large {
  width: 360px;
  margin-left: -180px;
  text-align-last: center;
}

.tooltip .bottomArrow {
  text-align: center;
  text-align-last: center;
}

.tooltip.xlarge {
  white-space: pre;
  width: 520px;
  margin-left: 26px;
  bottom: 0 !important;
  margin-bottom: -55px;
}

.tooltip.xxlarge {
  white-space: pre;
  width: 1000px;
  margin-left: 26px;
  bottom: 0 !important;
  margin-bottom: -55px;
}

.tooltip .leftArrow {
  top: -5px;
  left: 105%;
}

.tooltip .leftArrow::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #102E40 transparent transparent;
}

.tooltip .bottomArrow::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #102E40 transparent transparent transparent;
}


.tooltip .whiteBorder::after{
  border-color: white transparent transparent transparent !important;
}

.whiteBorderDiv {
  border: 1px solid white !important;
}
  </style>
  
