<template>
  <div class="page-footer">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PFooter",
};
</script>
