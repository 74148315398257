<template>
  <div class="d-f p-2">
    <div class="table-total mt-2" v-if="showTotal">{{ totalItems }}</div>
    <div class="ml-auto" v-if="showPagination">
      <button
        @click="previousPage"
        class="m-1 table-pagination-btn"
        :disabled="!enableBtns"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.3623 17C13.1003 17 12.8393 16.898 12.6433 16.695L8.7803 12.695C8.4023 12.302 8.4073 11.679 8.7933 11.293L12.7933 7.29301C13.1833 6.90201 13.8163 6.90201 14.2073 7.29301C14.5973 7.68401 14.5973 8.31601 14.2073 8.70701L10.9023 12.012L14.0813 15.305C14.4653 15.703 14.4543 16.336 14.0573 16.719C13.8623 16.907 13.6123 17 13.3623 17Z"
            fill="#828282"
          />
        </svg>
      </button>
      <template v-if="showAllPages">
        <button
          v-for="index in pages"
          :key="index"
          class="page m-1 table-pagination-btn"
          :class="{ selected: isPageSelected(index) }"
          @click="selectPage(index)"
        >
          {{ index }}
        </button>
      </template>
      <template v-else>
        <button
          v-if="showFirstPage"
          class="page m-1 table-pagination-btn"
          :class="{ selected: isPageSelected(1) }"
          @click="selectPage(1)"
        >
          1
        </button>
        <button
          v-if="showFirstPageDots"
          class="cursor-default page m-1 table-pagination-btn"
        >
          ...
        </button>
        <!-- <template v-for="index in maxPagesToShowAux"> -->
        <button
          :key="index"
          class="page m-1 table-pagination-btn"
          :class="{ selected: isPageSelected(selectedPage) }"
          @click="selectPage(selectedPage)"
        >
          {{ selectedPage }}
        </button>
        <!-- </template> -->

        <button
          v-if="showLastPageDotes"
          class="cursor-default page m-1 table-pagination-btn"
        >
          ...
        </button>
        <button
          v-if="showLastPage"
          class="page m-1 table-pagination-btn"
          :class="{ selected: isPageSelected(pages) }"
          @click="selectPage(pages)"
        >
          {{ pages }}
        </button>
      </template>
      <button
        @click="nextPage"
        class="m-1 table-pagination-btn"
        :disabled="!enableBtns"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.5 17C10.244 17 9.98801 16.902 9.79301 16.707C9.40201 16.316 9.40201 15.684 9.79301 15.293L13.098 11.988L9.91801 8.695C9.53501 8.297 9.54601 7.664 9.94301 7.281C10.341 6.898 10.974 6.909 11.357 7.305L15.219 11.305C15.598 11.698 15.593 12.321 15.207 12.707L11.207 16.707C11.012 16.902 10.756 17 10.5 17Z"
            fill="#828282"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
const maxPagesToShow = 5;
const pagesGap = maxPagesToShow - 2;

export default {
  props: {
    pages: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 10,
    },
    searchFunc: {
      type: Function,
      required: false,
    },
    showPagination: {
      type: Boolean,
      required: false,
      default: true,
    },
    showTotal: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      selectedPage: 1,
    };
  },
  computed: {
    pagesGapAux() {
      return pagesGap;
    },
    maxPagesToShowAux() {
      return maxPagesToShow;
    },
    enableBtns() {
      return this.pages > 1;
    },
    showAllPages() {
      return this.pages < maxPagesToShow;
    },
    middleBtns() {
      var dif = this.selectedPage - pagesGap;
      if (dif < 0) {
        dif = 0;
      }
      return dif;
    },
    showDots() {
      return this.selectedPage < this.pages - 1;
    },
    showLast() {
      return this.selectedPage != this.pages;
    },
    totalItems() {
      var records =
        this.selectedLang == null ? "Registos" : this.selectedLang.records;

      if (this.total == 1) {
        records =
          this.selectedLang == null ? "Registo" : this.selectedLang.record;
      }
      return `${this.total} ${records}`;
    },

    pageRangeAddon() {
      return this.selectedPage - pagesGap;
    },

    showFirstPage() {
      return this.selectedPage > 1;
    },
    showFirstPageDots() {
      return this.selectedPage > 2;
    },
    showLastPage() {
      return this.selectedPage < this.pages;
    },
    showLastPageDotes() {
      return this.selectedPage < this.pages - 1;
    },
  },
  methods: {
    isPageSelected(pageNumber) {
      return this.selectedPage == pageNumber;
    },
    selectPage(pageNumber) {
      this.selectedPage = pageNumber;
      this.changePage();
    },
    nextPage() {
      if (this.selectedPage < this.pages) {
        this.selectedPage++;
        this.changePage();
      }
    },
    previousPage() {
      if (this.selectedPage > 1) {
        this.selectedPage--;
        this.changePage();
      }
    },
    changePage() {
      if (this.searchFunc != null) {
        this.searchFunc(this.selectedPage);
      }
    },

    canshowBtn(btnIndex) {
      return btnIndex > 0 && btnIndex <= this.pages;
    },
  },
  mounted() {
    this.emitter.on("SortByHeader", () => {
      this.selectedPage = 1;
    });
    this.emitter.on("TableSort:ResetPage", (page) => {
      this.selectedPage = page ?? 1;
    });
  },
};
</script>
