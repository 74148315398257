<template>
  <input
    :class="{ invalid: showErrorMsg }"
    class="form-control"
    :placeholder="localPlaceholder"
    :disabled="disabled"
    ref="inputRef"
    type="text"
    :maxlength="maxlength"
  />
</template>

<script>
import { defineComponent, onMounted, ref, watch } from "vue";
import { NumberInput } from "intl-number-input";

export default defineComponent({
  data() {
    return {
      options: {
        formatStyle: "percent",
        hideCurrencySymbolOnFocus: false,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: true,
        valueScaling: "precision",
        useGrouping: true,
        accountingSign: false,
      },
    };
  },
  props: {
    showErrorMsg: {
      Boolean,
      default: false,
    },
    modelValue: [String, Number],
    disabled: {
      Boolean,
      default: false,
    },
    maxlength: {
      Number,
      default: 20,
    },
    placeholder: String,
    maxval: {
      Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    let numberInput = null;
    const inputRef = ref(null);

    const options = {
      formatStyle: "percent",
      locale: undefined,
      currency: undefined,
      currencyDisplay: undefined,
      unit: undefined,
      unitDisplay: undefined,
      valueRange: {
        min: 0,
        max: 1,
      },
      precision: 2,
      hidePrefixOrSuffixOnFocus: false,
      hideGroupingSeparatorOnFocus: false,
      hideNegligibleDecimalDigitsOnFocus: false,
      autoDecimalDigits: true,
      exportValueAsInteger: false,
      autoSign: false,
      useGrouping: true,
    };

    onMounted(() => {
      if (inputRef.value) {
        numberInput = new NumberInput({
          el: inputRef.value,
          options: options,
          onChange: (value) => {
            if (value > props.maxval) {
              emit("update:modelValue", props.maxval);
              numberInput.setValue(props.maxval);
            } else {
              emit("update:modelValue", value.number);
            }
          },
          onInput: (value) => {
            if (value > props.maxval) {
              emit("update:modelValue", props.maxval);
              numberInput.setValue(props.maxval);
            } else {
              emit("update:modelValue", value.number);
            }
          },
        });
        numberInput.setValue(props.modelValue);
      }
    });

    watch(
      () => props.modelValue,
      (modelValue) => {
        numberInput.setValue(modelValue);
      }
    );
    return {
      inputRef,
    };
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
    localPlaceholder() {
      return this.placeholder ?? this.selectedLang.percentPrompt;
    },
  },
});
</script>
