<template>
  <span class="badge" :class="badgeClass">{{ text }}</span>
</template>

<script>
export default {
  props: {
    text: String,
    type: Number,
  },
  computed: {
    badgeClass() {
      switch (this.type) {
        case 4:
        case 32:
        case 64:
          return "badge-warning";
        case 8:
          return "badge-success";
        case 16:
          return "badge-danger";
        default:
        case 1:
        case 2:
          return "badge-secondary";
      }
    },
  },
};
</script>
