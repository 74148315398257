<template>
  <input
    v-if="!disabled"
    :class="{ invalid: showErrorMsg }"
    class="form-control"
    :placeholder="localPlaceholder"
    :disabled="disabled"
    :maxlength="maxlength"
    ref="inputRef"
  />
  <input
    v-else
    :class="{ invalid: showErrorMsg }"
    class="form-control"
    :placeholder="localPlaceholder"
    :disabled="true"
    :maxlength="maxlength"
    v-model="localModelValue"
  />
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";
import { watch } from "vue";

export default {
  data() {
    return {
      options: {
        currency: "EUR",
        currencyDisplay: "symbol",
        hideCurrencySymbolOnFocus: false,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: true,
        valueScaling: "precision",
        useGrouping: true,
        accountingSign: false,
        locale: "pt-PT",
      },
    };
  },
  props: {
    showErrorMsg: {
      Boolean,
      default: false,
    },
    modelValue: [String, Number],
    disabled: {
      Boolean,
      default: false,
    },
    maxlength: {
      Number,
      default: 20,
    },
    placeholder: String,
  },
  mounted() {},
  setup(props, data) {
    const { inputRef, setOptions, setValue } = useCurrencyInput({
      currency: "EUR",
      hideCurrencySymbolOnFocus: false,
      hideGroupingSeparatorOnFocus: false,
      hideNegligibleDecimalDigitsOnFocus: false,
      autoDecimalDigits: true,
      valueScaling: "precision",
      useGrouping: true,
      accountingSign: false,
    });

    watch(
      () => props.modelValue, // Vue 2: props.value
      (value) => {
        if (!props.disabled) {
          setValue(value);
        }
      }
    );

    watch(
      () => data.options,
      (options) => {
        if (!props.disabled) {
          setOptions(options);
        }
      }
    );

    return { inputRef };
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
    formatCurrency(value) {
      if (value != null) {
        // Tenta converter o valor para um número
        let numericValue = parseFloat(value);

        // Verifica se a conversão foi bem-sucedida
        if (!isNaN(numericValue)) {
          let formatPt = new Intl.NumberFormat("pt-PT", {
            style: "currency",
            currency: "EUR",
          });

          // Divide por 100 para obter o valor correto
          var percent = numericValue / 100;
          return formatPt.format(percent);
        } else {
          // Se o valor não puder ser convertido para um número, retorna uma mensagem de erro ou valor padrão
          return value;
        }
      }

      // Caso value seja nulo ou indefinido, retorna uma mensagem de erro ou valor padrão
      return "";
    },
  },
  computed: {
    localModelValue: {
      get() {
        return this.formatCurrency(this.modelValue);
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
    localPlaceholder() {
      return this.placeholder ?? this.selectedLang.monetaryPrompt;
    },
  },
};
</script>
