<template>
  <div class="row pt-2">
    <div class="col-6" v-for="(item, index) in options" :key="index">
      <RadioBtn
        :groupName="groupName"
        :label="item.label"
        :inputValue="item.value"
        :disabled="disabled"
        @update="updateValue"
        v-model="localModelValue"
        :parseIntProp="parseIntProp"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: String,
    loading: {
      Boolean,
      default: false,
    },
    showErrorMsg: {
      Boolean,
      default: false,
    },
    modelValue: [String, Number, Boolean],
    disabled: {
      Boolean,
      default: false,
    },
    required: {
      Boolean,
      default: false,
    },
    maxlength: {
      Number,
      default: 20,
    },
    options: {
      Array,
      default: [],
    },
    groupName: {
      String,
      default: "Group",
    },
    parseIntProp: {
      Boolean,
      default: true,
    },
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
    localPlaceholder() {
      return this.placeholder ?? this.selectedLang.select;
    },
  },
};
</script>
