<template>
  <div class="">
    <div class="">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "PContent",
  data() {
    return {};
  },
};
</script>
