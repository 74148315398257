<template>
  <div class="tab-selector">
    <ul class="tab-selector-nav">
      <TabTitle
        v-for="(tab, index) in tabs"
        :key="index"
        :tab="tab"
        :tabName="tab.props.tabName"
        :selectedTabName="selectedTab"
        :selectTab="selectTab"
      />
    </ul>

    <div class="tab-content" ref="teste">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabSelector",
  props: {
    defaultSelectedTabName: { type: String, default: "" },
    group: { type: String, default: "" },
  },
  data() {
    return {
      tabs: [],
      selectedTabName: this.defaultSelectedTabName,
    };
  },
  mounted() {
    this.tabs = this.$slots.default();
    if (this.defaultSelectedTabName == "")
      this.selectedTabName = this.tabs[0].props.tabName;
    this.emitter.emit("select-tab", {
      tabName: this.selectedTabName,
      group: this.group,
    });
  },
  methods: {
    selectTab(selectedTab) {
      this.selectedTabName = selectedTab.props.tabName;
      this.emitter.emit("select-tab", {
        tabName: selectedTab.props.tabName,
        group: selectedTab.props.group,
      });
    },
  },
  computed: {
    selectedTab() {
      return this.selectedTabName;
    },
  },
};
</script>
