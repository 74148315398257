<template>
<div class="d-flex justify-content-center p-1">

          <div class="spinner-border" role="status">

            <span class="sr-only"> </span>

          </div>

        </div>
</template>

<script setup></script>
