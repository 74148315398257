<template>
  <VueMultiselect
    v-if="!loading"
    :class="{ invalid: showErrorMsg }"
    v-model="localModelValue"
    :options="localOptions"
    :searchable="searchable"
    :placeholder="localPlaceholder"
    :noResultsText="selectedLang.noResultsText"
    :noOptionsText="selectedLang.noResultsText"
    :mode="mode"
    :disabled="disabled"
    :multipleLabel="multipleLabel"
    :create-option="createOption"
    :on-create="localHandleTagCreate"
    :close-on-select="closeOnSelect"
    :canDeselect="canDeselect"
    :canClear="canClear"
    :groups="groups"
    :showOptions="showOptions"
    ref="select$"
    label="labelToShow"
    trackBy="label"
    @search-change="searchChange"
  >
    <template #beforelist v-if="selectAll">
      <a href="" @click.prevent="handleSelectAll">Select all</a>
      <a href="" @click.prevent="handleDeselectAll">Deselect all</a>
    </template>
    <template v-slot:noresults v-if="showOtherOption">
      <div class="multiselect-option" @click="selectOtherOption">
        {{ otherOptionValue.label }}
      </div>
    </template>
    <!-- <template v-slot:singleLabel="option">
      {{ option }}
    </template>
    <template v-slot:option="props"> {{ props.option.labelToShow }} </template> -->
  </VueMultiselect>
  <div v-else class="form-control">
    <LoadingSpinner />
  </div>
</template>

<script>
export default {
  props: {
    placeholder: String,
    showErrorMsg: {
      Boolean,
      default: false,
    },
    modelValue: [String, Number, Array],
    disabled: {
      Boolean,
      default: false,
    },
    canDeselect: {
      Boolean,
      default: true,
    },
    canClear: {
      Boolean,
      default: true,
    },
    closeOnSelect: {
      Boolean,
      default: true,
    },
    maxlength: {
      Number,
      default: 20,
    },
    options: {
      Array,
      default: [],
    },
    searchable: {
      Boolean,
      default: true,
    },
    mode: {
      String,
      default: "single", //multiple, tags
    },
    loading: {
      Boolean,
      default: false,
    },
    groups: {
      Boolean,
      default: false,
    },
    showOptions: {
      Boolean,
      default: true,
    },
    selectAll: {
      Boolean,
      default: false,
    },
    createOption: {
      Boolean,
      default: false,
    },
    handleTagCreate: {
      Function,
      default: null,
    },
    showOtherOption: {
      Boolean,
      default: false,
    },
    otherOptionValue: {
      Object,
      default: {
        value: -1,
        label: "Outro",
      },
    },
  },
  data() {
    return {
      select$: null,
      filteredOptions: this.options,
      search: null,
    };
  },
  mounted() {
    this.select$ = this.$refs.select$;
  },
  methods: {
    searchChange(event) {
      if (this.normalizeTxt(event) != event) {
        this.$refs.select$.search = this.normalizeTxt(event);
      }
    },
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
    multipleLabel(value) {
      var txt =
        value.length > 1
          ? this.selectedLang.optionsSelectedTxt
          : this.selectedLang.optionSelectedTxt;
      return `${value.length} ${txt}`;
    },
    localHandleTagCreate(newTag) {
      if (this.handleTagCreate != null) {
        return this.handleTagCreate(newTag);
      } else {
        const tag = {
          label: newTag.label,
          value: newTag.label,
        };

        return tag;
      }
    },
    handleSelectAll() {
      this.select$.selectAll();
    },
    handleDeselectAll() {
      this.select$.clear();
    },
    selectOtherOption() {
      this.$refs.select$.select(this.otherOptionValue.value);
      this.$refs.select$.close();
      //this.localModelValue = this.otherOptionValue.value;
    },
    normalize(label) {
      if (typeof label === "string" || label instanceof String) {
        return (
          label
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "") + label
        );
      } else {
        return label;
      }
    },
    normalizeTxt(label) {
      if (typeof label === "string" || label instanceof String) {
        return label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
      } else {
        return label;
      }
    },
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
    localOptions: {
      get() {
        let result = [];
        this.options.forEach((option) => {
          let value = option.value ?? option.value2;
          result.push({
            value: value,
            label: this.normalize(option.label),
            labelToShow: option.label,
          });
        });
        return result;
      },
      set(newValue) {
        this.$emit("update:options", newValue);
      },
    },
    localPlaceholder() {
      return this.placeholder ?? this.selectedLang.selectTxt;
    },
    localErrorMsg() {
      return this.placeholder ?? this.selectedLang.invalidValue;
    },
  },
};
</script>

<style scoped>
.loading {
  padding: 0;
}
</style>
