<template>
  <div class="toolbar py-3 py-lg-3">
    <div class="container-fluid flex-stack flex-wrap">
      <div class="page-title flex-column me-3">
        <h1 class="text-dark fw-bolder my-1 fs-3">{{ this.title }}</h1>
        <ul class="customBreadcrumb">
          <slot name="breadcrumb"></slot>
        </ul>
      </div>
      <div class="align-items-center py-2 py-md-1">
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageToolbar",
  props: {
    title: String,
  },
  data() {
    return { tabs: [] };
  },
  methods: {
    redirectToHome() {
      if (window.location.hash !== "#/") this.$router.replace("/");
    },
  },
};
</script>
