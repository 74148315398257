<template>
  <textarea
    :class="{ invalid: showErrorMsg }"
    class="form-control minH"
    :placeholder="localPlaceholder"
    v-model="localModelValue"
    :disabled="disabled"
    :maxlength="maxlength"
    rows="rows"
    ref="textarea"
    :title="tooltip"
  ></textarea>
</template>

<script>
export default {
  props: {
    placeholder: String,
    showErrorMsg: {
      Boolean,
      default: false,
    },
    modelValue: String,
    disabled: {
      Boolean,
      default: false,
    },
    required: {
      Boolean,
      default: false,
    },
    maxlength: {
      Number,
      default: 20,
    },
    rows: {
      Number,
      default: 1,
    },
    showTooltip: {
      Boolean,
      default:true
    }
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
    // resize() {
    //   const { textarea } = this.$refs;
    //   textarea.style.height = textarea.scrollHeight - 4 + "px";
    // },
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
    localPlaceholder() {
      return this.placeholder ?? this.selectedLang.add;
    },
    tooltip() {
      return this.showTooltip && this.disabled ? this.localModelValue : null;
    }
  },
  mounted() {

  },
};
</script>

<style scoped>
.minH {
  min-height: 45px !important;
}
</style>
