<template>
  <thead>
    <tr>
      <th v-if="checkBox" class="table-checkbox">
        <input
          class="form-checkbox"
          type="checkbox"
          v-model="checkboxModel"
          @click="selectAllBtn"
        />
      </th>
      <th v-for="(header, index) in headerData" v-bind:key="index">
        <div
          class="d-f"
          @click="selectHeader(index)"
          :class="{ btnAlike: canSort(index) }"
        >
          {{ header.title }}
          <div class="ml-auto" v-if="sortHeader && canSort(index)">
            <template v-if="header.class == 0">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.4096 20.9148C12.2106 21.1992 11.7894 21.1992 11.5904 20.9148L7.30071 14.7867C7.06874 14.4553 7.30582 14 7.71033 14H16.2897C16.6942 14 16.9313 14.4553 16.6993 14.7867L12.4096 20.9148Z"
                  fill="#D8D3D3"
                />
                <path
                  d="M12.4096 4.08517C12.2106 3.80081 11.7894 3.80081 11.5904 4.08517L7.30071 10.2133C7.06874 10.5447 7.30582 11 7.71033 11H16.2897C16.6942 11 16.9313 10.5447 16.6993 10.2133L12.4096 4.08517Z"
                  fill="#D8D3D3"
                />
              </svg>
            </template>
            <template v-if="header.class == 1">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.4096 20.9148C12.2106 21.1992 11.7894 21.1992 11.5904 20.9148L7.30071 14.7867C7.06874 14.4553 7.30582 14 7.71033 14H16.2897C16.6942 14 16.9313 14.4553 16.6993 14.7867L12.4096 20.9148Z"
                  fill="#D8D3D3"
                />
                <path
                  d="M12.4096 4.08517C12.2106 3.80081 11.7894 3.80081 11.5904 4.08517L7.30071 10.2133C7.06874 10.5447 7.30582 11 7.71033 11H16.2897C16.6942 11 16.9313 10.5447 16.6993 10.2133L12.4096 4.08517Z"
                  fill="#333333"
                />
              </svg>
            </template>
            <template v-if="header.class == 2">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.4096 20.9148C12.2106 21.1992 11.7894 21.1992 11.5904 20.9148L7.30071 14.7867C7.06874 14.4553 7.30582 14 7.71033 14H16.2897C16.6942 14 16.9313 14.4553 16.6993 14.7867L12.4096 20.9148Z"
                  fill="#333333"
                />
                <path
                  d="M12.4096 4.08517C12.2106 3.80081 11.7894 3.80081 11.5904 4.08517L7.30071 10.2133C7.06874 10.5447 7.30582 11 7.71033 11H16.2897C16.6942 11 16.9313 10.5447 16.6993 10.2133L12.4096 4.08517Z"
                  fill="#D8D3D3"
                />
              </svg>
            </template>
          </div>
        </div>
      </th>
      <th v-if="actions" class="table-checkbox"></th>
    </tr>
  </thead>
</template>

<script>
const sortType = {
  none: 0,
  up: 1,
  down: 2,
};
export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    sortHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
    checkBox: {
      type: Boolean,
      required: false,
    },
    actions: {
      type: Boolean,
      required: false,
    },
    headers: {
      type: Array,
      required: true,
    },
    defaultSort: {
      type: String,
      required: true,
    },
    sortFunc: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      headerData: [],
      checkboxModel: false,
    };
  },
  mounted() {
    this.emitter.on("Table-IsSelectAll", (value) => {
      this.checkboxModel = value;
    });

    this.headersToHeaderData();
  },
  methods: {
    headersToHeaderData() {
      var id = 0;
      this.headers.forEach((header) => {
        this.headerData.push({
          id: id,
          title: header.title,
          sort: header.sort,
          class: sortType.none,
        });

        id++;
      });
    },
    resetHeadersSort(headerId) {
      var oldClass = this.headerData[headerId].class;
      this.headerData.forEach((header) => {
        header.class = sortType.none;
      });
      this.changeSort(headerId, oldClass);
    },
    changeSort(headerId, oldClass) {
      var newClass = sortType.none;
      switch (oldClass) {
        case sortType.none:
          newClass = sortType.up;
          break;
        case sortType.up:
          newClass = sortType.down;
          break;
      }

      this.headerData[headerId].class = newClass;
    },
    selectHeader(headerId) {
      this.emitter.emit("SortByHeader");
      var header = this.headerData[headerId];

      if (header.sort != null && header.sort != "") {
        let asc = header.class == 0 ? "Asc" : "Desc";
        let headerSort = `${header.sort} ${asc}`;

        if (header.class == 2) {
          headerSort = `${this.defaultSort} Asc`;
        }

        if (this.sortFunc != null) {
          this.sortFunc(headerSort);
        }

        this.resetHeadersSort(headerId);
      }
    },
    canSort(headerId) {
      var header = this.headerData[headerId];

      return header.sort != null && header.sort != "";
    },
    selectAllBtn() {
      this.emitter.emit("Table-SelectAll", !this.checkboxModel);
    },
  },
  watch: {},
};
</script>
