<template>
  <label class="d-flex cursor-pointer">
    <span class="form-check form-check-custom form-check-solid mr-2">
      <input
        :disabled="disabled"
        class="form-check-input"
        type="radio"
        :name="groupName"
        :value="inputValue"
        @change="updateValue"
        v-model="localModelValue"
      />
    </span>
    <span class="d-flex flex-column"> {{ label }} </span>
  </label>
</template>
<script>
export default {
  name: "RadioButton",
  props: {
    groupName: String,
    label: String,
    inputValue: [Number, String, Boolean],
    disabled: {
      Boolean,
      default: false,
    },
    parseIntProp: {
      Boolean,
      default: true,
    },
    modelValue: [Number, String, Boolean],
  },
  methods: {
    updateValue(event) {
      let result = this.parseIntProp
        ? parseInt(event.target.value)
        : event.target.value;
      this.$emit("update:modelValue", result);
    },
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
};
</script>
