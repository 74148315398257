<template>
  <div class="input-group">
    <input
      :class="{ invalid: showErrorMsg, 'hide-text': hideText }"
      type="text"
      class="form-control"
      :placeholder="localPlaceholder"
      v-model="localModelValue"
      :disabled="disabled"
      :maxlength="maxlength"
    />

    <div class="input-group-append" :class="{ invalid: showErrorMsg }">
      <span class="input-group-text bl-0" @click="hideText = !hideText">
        <EyeIcon v-if="hideText" />
        <CloseEyeIcon v-else />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hideText: true,
    };
  },
  props: {
    placeholder: String,
    showErrorMsg: {
      Boolean,
      default: false,
    },
    modelValue: String,
    disabled: {
      Boolean,
      default: false,
    },
    required: {
      Boolean,
      default: false,
    },
    maxlength: {
      Number,
      default: 20,
    },
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
    localPlaceholder() {
      return this.placeholder ?? this.selectedLang.add;
    },
  },
};
</script>
