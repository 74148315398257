<template>
  <li
    @click.prevent="selectTab(tab)"
    class="tab-selector-nav-item"
    :class="{
      selected: isTabSelected,
    }"
  >
    <a class="nav-link">{{ tabName }} </a>
  </li>
</template>

<script>
export default {
  props: {
    tab: Object,
    tabName: String,
    selectedTabName: String,
    selectTab: Function,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    isTabSelected() {
      return this.tabName == this.selectedTabName;
    },
  },
  watch: {},
};
</script>

<style></style>
