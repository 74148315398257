<template>
  <input
    :class="{ invalid: showErrorMsg }"
    type="text"
    class="form-control"
    :placeholder="localPlaceholder"
    v-model="localModelValue"
    :disabled="disabled"
    :maxlength="maxlength"
    @keypress="mask_zip"
  />
</template>

<script>
export default {
  props: {
    placeholder: String,
    showErrorMsg: {
      Boolean,
      default: false,
    },
    modelValue: String,
    disabled: {
      Boolean,
      default: false,
    },
    required: {
      Boolean,
      default: false,
    },
    maxlength: {
      Number,
      default: 8,
    },
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
    mask_zip: function (event) {
      if (
        (event.key && event.key.match(/[a-zA-Z]/)) ||
        (this.localModelValue != null && this.localModelValue.length >= 8)
      ) {
        event.preventDefault();
      } else {
        if (this.localModelValue != null && this.localModelValue.length >= 4) {
          this.localModelValue =
            this.localModelValue.substr(0, 4) +
            "-" +
            this.localModelValue.substr(5); // in case people type "-"
        }
      }
      return this.localModelValue;
    },
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
    localPlaceholder() {
      return this.placeholder ?? this.selectedLang.add;
    },
  },
};
</script>
