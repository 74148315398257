<template>
  <tbody>
    <slot />
    <tr v-if="loading">
      <td colspan="1000">
        <div class="d-flex justify-content-center p-1">
          <div class="spinner-border" role="status">
            <span class="sr-only"> </span>
          </div>
        </div>
      </td>
    </tr>
    <tr v-if="showEmptyrowLocal">
      <td colspan="1000">
        Não há resultados para mostrar
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: {
    checkBox: {
      type: Boolean,
      required: false,
    },
    loading: {
      type: Boolean,
      required: true,
      default: true,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    showEmptyrow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    showEmptyrowLocal(){
      return !this.loading && this.showEmptyrow && this.totalItems == 0
    }
  },
};
</script>
