import components from'./components/components'

const plugin = {
  install (Vue) {
    Object.keys(components).forEach(name => {
        Vue.component(name, components[name]);
    })
  }
}

export default plugin