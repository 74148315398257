<template>
  <div>
    <div
      class="modal"
      v-bind:class="{
        fadeIn: visible,
        fadeOut: !visible,
        'modal-db': visible,
      }"
      tabindex="-1"
      aria-hidden="true"
    >
      <div
        class="modal-dialog"
        v-bind:class="{
          'modal-xxl': isSizeExtraExtraLarge,
          'modal-xl': isSizeExtraLarge,
          'modal-lg': isSizeLarge,
          'modal-sm': isSizeSmall,
          'modal-dialog-centered': isCenter,
        }"
      >
        <div class="modal-content">
          <div class="modal-header border-0 pb-0">
            {{ title }}

            <span class="btnAlike" @click="closeBtn" v-if="showCloseBtn">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5303 8.53033C16.8232 8.23744 16.8232 7.76256 16.5303 7.46967C16.2374 7.17678 15.7626 7.17678 15.4697 7.46967L16.5303 8.53033ZM7.46967 15.4697C7.17678 15.7626 7.17678 16.2374 7.46967 16.5303C7.76256 16.8232 8.23744 16.8232 8.53033 16.5303L7.46967 15.4697ZM8.53034 7.46967C8.23745 7.17678 7.76257 7.17678 7.46968 7.46967C7.17678 7.76256 7.17678 8.23744 7.46968 8.53033L8.53034 7.46967ZM15.4697 16.5303C15.7626 16.8232 16.2374 16.8232 16.5303 16.5303C16.8232 16.2374 16.8232 15.7626 16.5303 15.4697L15.4697 16.5303ZM15.4697 7.46967L7.46967 15.4697L8.53033 16.5303L16.5303 8.53033L15.4697 7.46967ZM7.46968 8.53033L15.4697 16.5303L16.5303 15.4697L8.53034 7.46967L7.46968 8.53033ZM20.25 12C20.25 16.5563 16.5563 20.25 12 20.25V21.75C17.3848 21.75 21.75 17.3848 21.75 12H20.25ZM12 20.25C7.44365 20.25 3.75 16.5563 3.75 12H2.25C2.25 17.3848 6.61522 21.75 12 21.75V20.25ZM3.75 12C3.75 7.44365 7.44365 3.75 12 3.75V2.25C6.61522 2.25 2.25 6.61522 2.25 12H3.75ZM12 3.75C16.5563 3.75 20.25 7.44365 20.25 12H21.75C21.75 6.61522 17.3848 2.25 12 2.25V3.75Z"
                  fill="#007AC4"
                />
              </svg>
            </span>
          </div>
          <hr class="rounded mb-0" />
          <div class="modal-header-warning">
            <slot name="customModalWarning"></slot>
          </div>
          <div class="modal-body pt-0 pb-15 px-5 px-xl-20">
            <template v-if="visible">
              <slot></slot>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalId: {
      String,
      required: true,
    },
    size: {
      String,
      default: "sm",
    },
    title: {
      String,
      default: "Atenção!",
    },
    isCenter: {
      Boolean,
      default: false,
    },
    showCloseBtn: {
      Boolean,
      default: true,
    },
  },
  data() {
    return {
      visibleData: false,
    };
  },
  methods: {
    changeModalVisibleState(modalId) {
      if (this.modalId == modalId) {
        this.visible = !this.visible;
      }
    },
    closeBtn() {
      this.emitter.emit("closeCustomModal", this.modalId);
    },
    openCustomModal(modalId) {
      if (this.modalId == modalId) {
        this.visible = true;
      }
    },
    closeCustomModal(modalId) {
      if (this.modalId == modalId) {
        this.visible = false;
      }
    },
  },
  computed: {
    isSizeSmall() {
      return this.size == "sm";
    },
    isSizeLarge() {
      return this.size == "lg";
    },
    isSizeExtraLarge() {
      return this.size == "xl";
    },
    isSizeExtraExtraLarge() {
      return this.size == "xxl";
    },
    showTitle() {
      return this.title != "";
    },
    visible: {
      get() {
        return this.visibleData;
      },
      set(newVal) {
        this.visibleData = newVal;
      },
    },
  },
  mounted() {
    this.emitter.on("changeModalVisibleState", (modalId) => {
      this.changeModalVisibleState(modalId);
    });
    this.emitter.on("openCustomModal", (modalId) => {
      this.openCustomModal(modalId);
    });
    this.emitter.on("closeCustomModal", (modalId) => {
      this.closeCustomModal(modalId);
    });
  },
  unmount() {
    this.emitter.off("openCustomModal");
    this.emitter.off("changeModalVisibleState");
    this.emitter.off("closeCustomModal");
  },
};
</script>
