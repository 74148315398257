<template>
  <div class="accordion">
    <div
      class="accordion-tittle btnAlike d-f"
      @click="toggleAccordion"
      :class="{
        active: isOpen,
      }"
    >
      <p>
        <InformationIcon
          class="informationIcon"
          v-if="tooltipInfo != null"
          v-tooltip="tooltipInfo"
        />
        {{ title }}
      </p>
      <span>
        <svg
          :class="{
            active: isOpen,
            notActive: !isOpen,
          }"
          width="24"
          height="20"
          viewBox="0 0 24 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_343_7589)">
            <path
              d="M8.63007 6.99084C6.74744 6.99084 5.90762 9.35496 7.36834 10.5426L10.7383 13.2826C11.4733 13.8803 12.5267 13.8803 13.2617 13.2826L16.6317 10.5426C18.0924 9.35496 17.2526 6.99084 15.3699 6.99084L8.63007 6.99084Z"
              fill="#6B737A"
            />
          </g>
          <defs>
            <clipPath id="clip0_343_7589">
              <rect
                width="24"
                height="19.5138"
                fill="white"
                transform="translate(0 0.486206)"
              />
            </clipPath>
          </defs>
        </svg>
        <svg
          :class="{
            active: !isOpen,
            notActive: isOpen,
          }"
          width="24"
          height="20"
          viewBox="0 0 24 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_2719_8722)">
            <path
              d="M15.3699 13.4954C17.2526 13.4954 18.0924 11.1313 16.6317 9.94363L13.2617 7.20362C12.5267 6.60601 11.4733 6.60601 10.7383 7.20362L7.36835 9.94363C5.90762 11.1313 6.74744 13.4954 8.63007 13.4954L15.3699 13.4954Z"
              fill="#007AC4"
            />
          </g>
          <defs>
            <clipPath id="clip0_2719_8722">
              <rect
                width="24"
                height="19.5138"
                fill="white"
                transform="translate(24 20) rotate(-180)"
              />
            </clipPath>
          </defs>
        </svg>
      </span>
    </div>
    <hr class="rounded accordion-hr" v-if="isOpen" />
    <div class="accordion-body" v-if="isOpen">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: this.isOpenDefault,
      id: this.uuidv4(),
    };
  },
  mounted() {
    this.emitter.on("autoClose", (titleNotToClose) => {
      this.autoCloseFunction(titleNotToClose);
    });
  },
  props: {
    title: {
      String,
      default: "Title",
    },
    isOpenDefault: {
      Boolean,
      default: false,
    },
    autoClose: {
      Boolean,
      default: false,
    },
    tooltipInfo: {
      String,
      default: null,
    },
  },
  methods: {
    toggleAccordion() {
      let isOpenAux = this.isOpen;
      this.emitter.emit("autoClose", this.id);
      this.isOpen = !isOpenAux;
    },
    autoCloseFunction(idNotToClose) {
      if (this.autoClose && this.id != idNotToClose) {
        this.isOpen = false;
      }
      if (this.id == idNotToClose) {
        this.isOpen = true;
      }
    },
    uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
  },
};
</script>
<style scoped>
.informationIcon {
  min-width: 25px !important;
  min-height: 25px !important;
  margin-right: 5px;
}
</style>
