<template>
  <div class="p-3 d-f">
    <div class="perfilContent mr-5">
      <p class="username">{{ props.username }}</p>
      <router-link
        class="perfilName"
        :to="{ name: props.route }"
        v-if="enablePerfilLink"
        >{{ props.perfilName }}</router-link
      >
    </div>

    <span class="btnAlike" @click="logOut">
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.375 15L15.625 11.25M5.625 15L19.375 15L5.625 15ZM19.375 15L15.625 18.75L19.375 15Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23.75 10L23.75 20C23.75 21.2329 23.7441 21.6311 23.6961 21.9344C23.3996 23.8064 21.9314 25.2746 20.0594 25.5711C19.756 25.6192 19.3579 25.625 18.125 25.625C16.8921 25.625 16.494 25.6192 16.1906 25.5711C14.3186 25.2746 12.8504 23.8064 12.5539 21.9344C12.5059 21.6311 12.5 21.2329 12.5 20L12.5 19.6875C12.5 19.1697 12.0803 18.75 11.5625 18.75C11.0447 18.75 10.625 19.1697 10.625 19.6875L10.625 20C10.625 21.1613 10.625 21.7419 10.7019 22.2277C11.1255 24.902 13.223 26.9995 15.8973 27.423C16.3831 27.5 16.9637 27.5 18.125 27.5C19.2863 27.5 19.8669 27.5 20.3527 27.423C23.027 26.9995 25.1245 24.902 25.548 22.2277C25.625 21.7419 25.625 21.1613 25.625 20L25.625 10C25.625 8.83875 25.625 8.25812 25.548 7.77225C25.1245 5.098 23.027 3.0005 20.3527 2.577C19.8669 2.5 19.2862 2.5 18.125 2.5C16.9637 2.5 16.3831 2.5 15.8972 2.577C13.223 3.0005 11.1255 5.098 10.7019 7.77225C10.625 8.25813 10.625 8.83875 10.625 10L10.625 10.3125C10.625 10.8302 11.0447 11.25 11.5625 11.25C12.0803 11.25 12.5 10.8302 12.5 10.3125L12.5 10C12.5 8.76712 12.5059 8.369 12.5539 8.06562C12.8504 6.19362 14.3186 4.72538 16.1906 4.42887C16.494 4.38087 16.8921 4.375 18.125 4.375C19.3579 4.375 19.756 4.38087 20.0594 4.42887C21.9314 4.72538 23.3996 6.19362 23.6961 8.06562C23.7441 8.369 23.75 8.76712 23.75 10Z"
          fill="white"
        />
      </svg>
    </span>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue";
var props = defineProps({
  username: String,
  perfilName: String,
  logOutFunc: Function,
  enablePerfilLink: {
    Boolean,
    default: true,
  },
  route: {
    String,
    default: "Perfil",
  },
});

var logOutFunc = ref(props.logOutFunc);

async function logOut() {
  if (logOutFunc.value != null && typeof logOutFunc.value === "function") {
    logOutFunc.value();
  }
}
</script>

<style scoped></style>
