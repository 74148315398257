<template>
  <div class="card card-flush h-md-100" :class="size">
    <div class="card-header pt-7" v-if="title || subtitle">
      <h3 v-if="title" class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder text-gray-800">
          {{ title }}
        </span>
        <span v-if="subtitle" class="text-gray-400 mt-1 fw-bold fs-6">{{
          subtitle
        }}</span>
      </h3>
    </div>
    <div class="card-body pt-6">
      <slot> </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomCard",
  props: {
    title: String,
    subtitle: String,
    size: {
      type: String,
      default: "l",
    },
  },
  data() {
    return {};
  },
};
</script>
